import * as React from 'react'
import { SEO, Page } from '@common/components'
import { Header, Blog, Footer } from '../components'

const BlogPage = () => (
  <Page
    seoComponent={<SEO title="Blog" keywords={['basefactor', 'blog']} />}
    headerComponent={<Header />}
  >
    <Blog />
    <Footer enableTopBackground={false} />
  </Page>
)

export default BlogPage
